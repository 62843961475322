import { create } from '@storybook/theming/create';
import brandImage from '../website/public/dlabs.png';

export default create({
  base: 'light',

  colorPrimary: '#3d6af3',
  colorSecondary: '#3d6af3',

  appBg: '#fff',
  appContentBg: '#fff',
  appBorderColor: '#9ca3af',
  appBorderRadius: 5,

  fontBase:
    "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  fontCode: "SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",

  textColor: '#111827',
  textInverseColor: '#fff',

  barTextColor: '#4a5568',
  barSelectedColor: '#0479d1',
  barBg: '#edf2f7',

  inputBg: '#fff',
  inputBorder: '#9ca3af',
  inputTextColor: '#111827',
  inputBorderRadius: 5,

  brandTitle: 'DLabs UI',
  brandUrl: 'https://www.disruptivelabs.io',
  brandImage,
});
